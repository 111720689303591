<div class="content single-show-content" *ngIf="show && !brand">
	<h2 class="popup-heading single-show">
		{{ show?.custom_name + " [" + show?.show_code + "]" }} {{(show?.copy_of ? ' - Copy of ' + show?.copy_of : '' )}}
		<span> {{ show?.created_date_ts|date}} | {{show?.platform === 'AUDIO_APP' ? siteName : (show?.platform === 'CSC' ? 'COBRA': show?.platform)}}{{show?.timecode_channel === 'right' ? ' | SMPTE (Right Channel)' : (show?.timecode_channel === 'processing' ? ' | Processing SMPTE...' : '')}}</span>
		<span [class]="(!loaded ? ' transparent' : '')" cdkFocusRegionstart>{{ show?.audio_file_name }}.mp3</span>
		<mat-dialog-actions>
			<button mat-button [routerLink]="['/home']">Back To Home</button>
		</mat-dialog-actions>
	</h2>
	<div *ngIf="show?.audio_file_id && show?.audio_file_name" class="wave-container">
		<div id="waveform" [class]="!loaded ? 'transparent' : ''"></div>
		<div id="wave-timeline" [class]="!loaded ? 'transparent' : ''"></div>
		<div class="timer" [hidden]="!loaded || show.looping">{{ seekTime | date:'HH:mm:ss.S' }}</div>
		<div class="music-box" *ngIf="loaded && !show.looping">
			<h4>Adjust Audio Alignment</h4>
			<div class="musix-controls">
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" (click)="adjust(false)"> too early</button>
				<h5>{{ ( (adjustment) > 0 ? '+' : '' ) + (adjustment).toFixed(1) + 's' }}</h5>
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="adjust(true)"> too late</button>
			</div>
			<p>When playing audio from a web browser, you can fine tune the alignment using the below controls. Please note this technology is currently in BETA with ongoing optimizations in development.</p>
		</div>
		<div class="countdown" *ngIf="countdown > 0"><span>Show starts in <b>{{ countdown }}</b> seconds</span></div>
		<div class="progress-bar-container" style="position: relative" *ngIf="!loaded && progress > 0">
			<span *ngIf="progress < 100">{{ this.newAudioFile ? 'Retrieving new audio file ' + (show?.audio_file_name) +'.mp3. Please wait...':'Loading...' }} <span *ngIf="fileInfo && progress > 0">{{ progress }}%</span><span *ngIf="!fileInfo || progress == 0"><mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner></span></span>
			<span *ngIf="progress === 100">Loading show, please wait...</span>
			<mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
		</div>
		<div style="text-align: center;"><button *ngIf="buttonVisible" mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="init()" id="button">Listen to show!</button></div>
	</div>
	<div *ngIf="show && show.audio_file_id && loadingError">
			<p>Error loading audio file. Either the file not found or it is corrupted. Click edit audio to upload new audio file.</p>
	</div>
	<div *ngIf="show && !show.audio_file_id">
			<p>No audio file is attached to this show. For shows created from the PYROCAST platform, please click Add Audio from the show listing to add an audio file.</p>
			<p>If the show was created from COBRA or IGNITE, please use COBRA Show Creator or IGNITE Show Designer to load the audio file directly. Once loaded, the audio file will appear in PYROCAST automatically.</p>
	</div>
	<div *ngIf="show && show.audio_file_id && !show.audio_file_name">
			<p>Unfortunately no audio file is associated with this show. Please contact the show owner or <a href="mailto:help@pyrocast.com">help&#64;pyrocast.com</a> if you need further assistance..</p>
	</div>
</div>
<div *ngIf="show && brand" class="edit-brand" [ngStyle]="{'background-color': brand?.background_color ?? '' }">
	<div class="edit-header">
		<div style="height: 120px;">
			<img [src]="brand?.header_image + '?v=' + random" *ngIf="brand?.header_image" />
		</div>
	</div>
	<div class="edit-content">
		<h3> <b [ngStyle]="{'color': brand?.heading_color ?? '', 'font-family': font.family}"> {{ show.custom_name }} </b>
			<a [href]="show.website_url" *ngIf="show.website_url" target="_blank" [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ showDomainOnly(show.website_url) }} </a>
		</h3>
		<span [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.address1 }} </span>
		<span [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.address2 }} </span>
		<span style="margin-bottom: 20px;" [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.city }}{{(show.state ? ', ' + show.state : '')}}{{(show.country ? ', ' + show.country : '') }}</span>

		<h3 [ngStyle]="{'color': brand?.heading_color ?? '', 'font-family': font.family }">
			<span *ngIf="show.show_date_ts && !show.datetime_text">{{ show.show_date_ts | date: 'MMMM d, y' }}{{show.show_time ? ', at ' + formatTime(show.show_time) : ''}}</span>
			<span *ngIf="show.datetime_text">{{ show.datetime_text ? show.datetime_text : '' }}</span>
		</h3>
		<p [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }" [innerHTML]="nl2br(show.description)"></p>
		<p [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }">{{ announcementComment }}</p>
		<div class="wave-container">
			<div id="waveform" [class]="!loaded ? 'transparent' : ''"></div>
			<div id="wave-timeline" [class]="!loaded ? 'transparent' : ''"></div>
			<div class="progress-bar-container" [ngStyle]="{'color': brand?.heading_color ?? '', 'font-family': font.family}" *ngIf="!loaded && progress > 0">
				<span *ngIf="progress < 100">{{ this.newAudioFile ? 'Retrieving new audio file ' + (show?.audio_file_name) +'.mp3. Please wait...':'Loading...' }} <span *ngIf="fileInfo && progress > 0">{{ progress }}%</span><span *ngIf="!fileInfo || progress == 0"><mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner></span></span>
				<span *ngIf="progress === 100">Loading show, please wait...</span>
				<mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
			</div>
			<button mat-raised-button *ngIf="buttonVisible" [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}" (click)="init()" id="button">Listen to Show</button>
		</div>
		<div class="event-button" *ngIf="show.extra_button_url">
			<a [href]="show.extra_button_url" target="_blank" rel="noopener" mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}">{{ show.extra_button_text }}</a>
		</div>
		<div class="event-button">
			<a [href]="'https://maps.google.com/?q=' + show.address1 + (show.address2 ? ' ' + show.address2 : '') + (show.city ? ', ' + show.city : '') + (show.state ? ', ' + show.state: '') + (show.country ? ', ' + show.country: '')" mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}" target="_blank">Map Directions</a>
		</div>
		<!-- <div class="event-button">
			<button mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}">Back to Menu</button>
		</div> -->
	</div>
	<div class="edit-footer">
		<div style="height: 90px;">
			<img [src]="brand?.footer_image + '?v=' + random" *ngIf="brand?.footer_image" />
		</div>
	</div>
</div>
<div class="content single-show-content" *ngIf="!show">
	<div class="login" *ngIf="!loaded">
		<div>
			Please wait...
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
	</div>
	<div *ngIf="loaded">
		<p>Unfortunately this show does not exist. Please contact the show owner or <a href="mailto:{{email}}">{{email}}</a> if you need further assistance..</p>
	</div>
</div>
